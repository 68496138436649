<template>
  <div>
    <div v-if="error_message" v-html="error_message" class="alert alert-danger"></div>
    <div v-if="success_message" v-html="success_message" class="alert alert-success"></div>
    <q-linear-progress color="blue-grey-6" indeterminate :class="{ invisible: !loading }" />
    <b-row v-if="account">
      <b-col xl="12" lg="12" md="12" sm="12">
        <EditableCard
          title="Bottle On Hand Summary"
          :params.sync="account"
          :relationship="$d.getRelationship('Account', 'HasReturnableTangibleSummary')"
          direction="to"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { QLinearProgress } from 'quasar';

export default {
  components: {
    QLinearProgress,
    EditableCard: () => import('@/descriptor/coreui/EditableCard'),
    DescriptorCard: () => import('@/descriptor/coreui/DescriptorCard'),
  },
  props: {
    account: {
      required: true,
    },
  },
  data: () => {
    return {
      error_message: null,
      success_message: null,
      loading: null,

      account_: null,
    };
  },
  computed: {
    id() {
      return _.get(this.account, ['id'], null);
    },
    read_params() {
      return {
        with_relationships: [
          {
            from_class_name: 'Account',
            relationship_name: 'HasReturnableTangibleSummary',
            with_virtual_properties: true,
          },
        ],
      };
    },
  },
  watch: {
    account: {
      immediate: true,
      handler(to, from) {
        this.get();
      },
    },
  },
  // created() {},
  methods: {
    get() {
      if (this.id) {
        this.loading = true;
        this.$d.request(
          'read',
          'Account',
          Object.assign(this.read_params, {
            id: this.id,
          }),
          account => {
            // this.$d.setCache(stateName, account);
            // this.account_ = account;

            this.error_message = null;
          },
          error_message => {
            this.error_message = error_message;
          },
          () => {
            this.loading = false;
          }
        );
      } else {
        console.error('no id, cannot read');
      }
    },
  },
};
</script>
